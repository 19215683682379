import React, { useState, useContext } from 'react';
import CvRiskChart from './CvRiskChart';
import CvRiskRadialChart from './CvRiskRadialChart';
import SnsShare from './SnsShare';
import { NavLink } from 'react-router-dom';
import { CvriskContext } from './Covid19';
import { useTranslation } from 'react-i18next';
import './i18n'

// del
// kit_cv_lite
// kit_cv_prm
// kit_ge2

function setResult(cvrisk) {
  const cv_result = JSON.parse(cvrisk).cv_result;

  const risk_lvl_css = `level -${(cv_result.risk_lvl === 'Very High') ? 'high' : cv_result.risk_lvl.toLowerCase()}`;

  const cv_risk = {
    "hazard_ratio": cv_result.hazard_ratio,
    "risk_lvl": cv_result.risk_lvl,
    "risk_lvl_css": risk_lvl_css,
    "covid_age": cv_result.covid_age,
		"risk_def": cv_result.risk_def,
		"work_rec": cv_result.work_rec,
    "pre_caut": cv_result.pre_caut,
		"fig": cv_result.fig,
		"ifr": {
			"max": cv_result.ifr.max,
			"min": cv_result.ifr.min
		}
  }
  return cv_risk;
}

const CvResult = props => {
  const [cvrisk, setCvrisk] = useContext(CvriskContext);
  const cv_risk = setResult(cvrisk);

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const openFunc = () => {
    setOpen(true);
    setClose(false);
  };
  const closeFunc = () => {
    setOpen(false);
    setClose(true);
  };

  // console.log(cv_risk);

  const { t } = useTranslation();

  const score_range = {
    "en": {
      "very high": "A score above 8.75 is considered a",
      "high": "A score equal to 7.2 and below 8.75 is considered a",
      "moderate": "A score equal to 5.15 and below 7.2 is considered a",
      "low": "A score below 5.15 is considered a",
      "none": "A score below 0 is considered a"
    },
    "ja": {
      "very high": "8.75より大きいスコアは、非常に高リスクと考えられます。",
      "high": "7.2以上8.75未満のスコアは、高リスクと考えられます。",
      "moderate": "5.15以上7.2未満のスコアは、中程度のリスクと考えられます。",
      "low": "5.15より小さいスコアは、低リスクと考えられます。",
      "none": "A score below 0 is considered a"
    }
  };

  const rep_br = str => { return str.split('\n').map(str => { return(<React.Fragment>{rep_span(str)}<br /></React.Fragment>) })};

  const rep_span = str => {return str.split('\r').map((str, i) => { return (i  > 0) ? (<span>{str}</span>) : str })};

  return (
    <div className="coid_score_bg">
        <section>
          <div className="wrap">
            {/* <ChangeLng setLang={props.setLang} lang={props.lang} /> */}
            <div className="toptitle">
              <h1>{ t('Your COVID-19 Health Hazard Score') }</h1>
              <NavLink to="/" exact><span className="del"><img src="./image/del.svg" alt=""/></span></NavLink>
            </div>
            <div className="covid_score_wrap">
              <div className="inner">
                <div className="score_top">
                  <div className="summary">
                    <div>{ score_range[props.lang][cv_risk.risk_lvl.toLowerCase()] }</div>
                    <div className={cv_risk.risk_lvl_css}>{cv_risk.risk_lvl.toUpperCase()}</div>
                    {/*<div className="level -mid">MID</div>
                    <div className="level -low">LOW</div>*/}
                    <div>{ t('hazard risk') }</div>
                  </div>
                  <div className="graph">
                  <CvRiskRadialChart values={cv_risk} />
                  </div>
                </div>

                <div className="toptex">
                  <p>{cv_risk.risk_def.split('\n').map(str => { return(<React.Fragment>{str}<br /></React.Fragment>) })}</p>
                </div>

                <div className="reco">
                  <div className="accord">
                    <div className="emp">{ t('WORKPLACE CONSIDERATIONS') }</div>
                    <p>{rep_br(cv_risk.work_rec)}</p>
                    <div className="emp">{ t('PRECAUTIONS') }</div>
                    <p>{rep_br(cv_risk.pre_caut)}</p>
                    <div className="emp">{ t('PREGNANCY') }</div>
                    <p className={(open) ? "open" : "close"}>{ rep_br(t('pregnancy_info'))}</p>
                  </div>
                  <div className={`more ${(open) ? "hidden" : "close"}`} onClick={openFunc}>{ t('Show more') }</div>
                </div>
                <div className="chart">

                  <CvRiskChart values={cv_risk.fig} />
                </div>
                <div className="annot">
                  <h2>{ t('About the COVID-19 Health Hazard Score') }</h2>
                  <p>{ t('Regardless of your COVID-19 Health Hazard Score, it is important to take and maintain all precautionary actions to prevent exposure and spread of the virus.') }<br /><br />
                     { t('Remain informed about the evolution of the virus by consulting regularly the guidelines issued by health authorities and consult a medical practitioner whenever you might have some questions.') }<br /><br />
                     { t('To learn more about the science behind the COVID-19 Health Hazard Score, ') }<a href="/whitepaper.pdf" target="_blank">{ t('click here.') }</a></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="wrap">
            <div className="share">
              <h2>{ t('Share with friends and family') }</h2>
              <SnsShare />
              {/* <div className="btn_share"><a href="#">Share</a></div> */}
            </div>
          </div>
        </section>
        <section>
          <div className="wrap">
            <div className="covid_kits">
              <div className="box">
                <div className="covid_score_wrap">
                  <div className="inner kit">
                    <h2>{ t('NEED A COVID-19 PCR TEST?') }</h2>
                    <p className="text">{ t('Order a GeneLife COVID-19 PCR kit according to Japanese testing protocols, even if asymptomatic.') }</p>
                    <div className="kitimg"><img src="/image/kit_cv_lite.png" alt="covid19"/></div>
                    <div className="btn_oder"><a target="_blank" href="https://genelife.jp/covid19pcr/">{ t('Order now') }</a></div>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="covid_score_wrap">
                  <div className="inner kit">
                    <h2>{ t('NEED A COVID-19 PCR TEST?') }</h2>
                    <p className="text">{ t('Order a GeneLife COVID-19 PCR kit with global testing protocols! ') } </p>
                    <div className="kitimg"><img src="/image/kit_cv_prm.png" alt="covid19"/></div>
                    <div className="btn_oder"><a target="_blank" href="https://genelife.jp/covid19pcr_premium/">{ t('Order now') }</a></div>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="covid_score_wrap">
                  <div className="inner kit">
                    <h2>{ t('Learn from your genetics about COVID-19 and how to maximize your health and wellness during pandemic times.') }</h2>
                    <p className="text">{ t('Order Genesis2.0 Plus with special COVID-19 report.') }</p>
                    <div className="kitimg"><img src="/image/kit_ge2_plus.png" alt="genesis2"/></div>
                    <div className="btn_oder"><a target="_blank" href="https://sales.genelife.jp/product/genesis2">{ t('Order now') }</a></div>
                  </div>
                </div>
                <div className="outer kit">
                  { t('For existing Myself2.0 customers, purchase Genesis2.0 with special COVID-19 report at a discounted price via our mobile app.') }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  );
};

export default CvResult
