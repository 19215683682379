import { useContext, React } from 'react';
import { CvriskContext } from './Covid19';
import { useTranslation } from 'react-i18next';
import './i18n'

const SnsShare = () => {
  const [cvrisk, setCvrisk] = useContext(CvriskContext);
  const { t } = useTranslation();

  return(
    <div className="icons-wrapper">
      <a href={ t('facebook') } target="_blank"><span className="sns facebook"></span></a>
      <a href={ t('twitter') } target="_blank"><span className="sns twitter"></span></a>
    </div>);
};

export default SnsShare;
