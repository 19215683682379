import React from 'react';
import Chart from "react-apexcharts";

const CvRiskRadialChart = props => {
  const max_hazard_score = 8.74;
  const { values } = props;
  const hazard_score = values.hazard_ratio;
  const ratio = Math.round(hazard_score / max_hazard_score * 100);

  const barcolor_base = {
    "very high": ["#e7b8b6", "#E56E69"],
    "high": ["#e7b8b6", "#E56E69"],
    "moderate": ["#ffd1a7", "#F2AE6F"],
    "low": ["#69D6E5", "#c0f5fc"],
  }
  const barcolor = barcolor_base[values.risk_lvl.toLowerCase()];
  const chcatval = {
    series: [ratio],
    options: {
      chart: {
        height: 350,
        type: 'radialBar'
      },
      plotOptions: {
        radialBar: {
           hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: 28,
              show: true,
              color: '#888',
              fontSize: '12px'
            },
            value: {
              formatter: function(val) {
                return hazard_score;
              },
              color: '#111',
              fontSize: '40px',
              fontWeight: '600',
              show: true,
              offsetY: -12,
            },
          }
        }
      },
      stroke: {
        width: 2,
        lineCap: 'round'
      },
      fill: {
        colors: [barcolor[0]],
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: [barcolor[1]],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      markers: {
        size: 0
      },
      labels: ['Hazard Score']
    }
  }

  return (<div className="app">
    <div className="row">
      <div className="mixed-chart">
        <Chart options={chcatval.options} series={chcatval.series} type="radialBar" width="100%"/>
      </div>
    </div>
  </div>);
};

export default CvRiskRadialChart
