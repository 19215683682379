import React, { useState } from 'react';
// import { Document, Page } from 'react-pdf';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function WhitePaper() {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return(
    <div className="coid_score_bg">
      <div className="wrap">
        <Document
          file="/whitepaper.pdf"
          onLoadSuccess={onDocumentLoadSuccess} >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>Page {pageNumber} of {numPages}</p>
      </div>
    </div>
  );
}

export default WhitePaper;
