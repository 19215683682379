import './App.css';
import Covid19 from './Covid19';

function App() {
  document.title = "COVID-19 Health Hazard Score";
  
  return (
    <div className="App">
      <Covid19 />
    </div>
  );
}

export default App;
