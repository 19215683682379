import { useContext } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Form, Field, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { CvriskContext } from './Covid19';
import './i18n'
import ChangeLng from './ChangeLng';

const PostForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  const [cvrisk, setCvrisk] = useContext(CvriskContext);
  const history = useHistory();

  const { t } = useTranslation();
  const validation_error_msg = t('Invalid age.');

  function toResult(errors) {
    console.log('result');
    if (errors.age) {
      console.log(errors);
    } else {
      const url = createUrl(values);
      // console.log(url);
      axios
      .get(url, {
        headers: {
          Authorization: "Bearer 8213f5cd-5fds2-4891-83d0-48d172ffab77"
        }
      })
      .then(results => {
        setCvrisk(() => JSON.stringify(results.data.message));
        // console.log(results.data.message);
        history.push('/covid19/result');
      });
    }
  };

  function createUrl(values) {
    const endpoint = 'https://9ckhs49pqh.execute-api.ap-northeast-1.amazonaws.com/dev/cvrisk';
    const params = Object.keys(values).map(k => { return `${k}=${values[k]}` }).join('&') + `&lang=${props.lang}`;
    return `${endpoint}?${params}`;
  }

  return (

    <div className="coid_score_bg">
      <div className="wrap">
        <ChangeLng setLang={props.setLang} lang={props.lang} />
        <div className="genelife_logo"><img src="./image/genelifelogo.png" alt="genelife" /></div>
          <div className="form_top">
            <h1>{ t('COVID-19 Health Hazard Score') }</h1>
            <h2>{ t('Based on Health Conditions') }</h2>
            <p className="topsubtext">{ t('Assess your vulnerability for developing severe COVID-19 and related health hazard risks.') }</p>
          </div>
        <div className="covid_score_wrap">
          <div className="inner">
            <h1>{ t('Health risks') }</h1>
              <p className="formsubtext">{ t('Fill the medical conditions questionnaire below to assess your COVID-19 Health Hazard Score.') }</p>
            <Form className="form">
              <div className="field -short">
                <label htmlFor="Age">{ t('Age (minimum 20-years old, maximum 75 years old)') }</label>
                <p className="annot">{ t('Research has focused on patients from 20 to 75 years old and as a result the COVID-10 Health Hazard Score will only be applied to this age group.') }</p>
                <Field id="age" name="age" onChange={handleChange} onBlur={handleBlur} placeholder="20" />
              </div>
              <div className="field -short">
                <label htmlFor="Gender">{ t('Gender') }</label>
                <label><Field id="gender" name="gender" type="radio" value="M" />{ t('Male') }</label>
                <label><Field id="gender" name="gender" type="radio" value="F" />{ t('Female') }</label>
              </div>
              <div className="field -short">
                <label htmlFor="Height">{ t('Height (cm)') }</label>
                <Field id="height" name="height" placeholder="160" />
              </div>
              <div className="field -short">
                <label htmlFor="Height">{ t('Weight (kg)') }</label>
                <Field id="weight" name="weight" placeholder="50" />
              </div>
              {/* <p className="bmi">Calculated BMI:<span>0</span></p> */}

              <div className="field -full">
                <label htmlFor="Ethnicity">{ t('Ethnicity') }</label>
                <Field name="ethnicity" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="Japanese">{ t('Japanese') }</option>
                  <option value="South Asian">{ t('Asian (South or East Asian)') }</option>
                  <option value="White">{ t('Caucasian or East European') }</option>
                  <option value="Black">{ t('African or African American') }</option>
                  <option value="Mixed">{ t('Mixed ethnicities') }</option>
                  <option value="Other non-white">{ t('None of the above') }</option>
                </Field>
              </div>

              <div className="field -full">
                <label htmlFor="Asthma">{ t('Asthma') }</label>
                <Field name="asthma" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="Mild">{ t('Mild') }</option>
                  <option value="Severe">{ t('Severe') }</option>
                </Field>
              </div>

              <div className="field -full">
                <label htmlFor="Diabetes">{ t('Diabetes') }</label>
                <Field name="diabetes" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="1.1">{ t('Type 1 HbA1c less than or equal to 7.5% (58 mmol/mol) in past year') }</option>
                  <option value="1.2">{ t('Type 1 HbA1c greater than 7.5% (58 mmol/mol) in past year') }</option>
                  <option value="1.3">{ t('Type 1 HbA1c unknown')}</option>
                  <option value="2.1">{ t('Type 2 and other HbA1c less than or equal to 7.5% (58 mmol/mol) in past year') }</option>
                  <option value="2.2">{ t('Type 2 and other HbA1c greater than 7.5% (58 mmol/mol) in past year') }</option>
                  <option value="2.3">{ t('Type 2 and other HbA1c unknown')}</option>
                </Field>
              </div>

              <div className="field -full">
                <label htmlFor="Chronic">{ t('Chronic kidney disease') }</label>
                <p className="annot">{ t('(Glomerular filtration rate <60mL/min/1.73m2, as estimated from the most recent serum creatinine measurement)') }</p>
                <Field name="kidney" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="1">{ t('Estimated GFR 30-60 mL/min') }</option>
                  <option value="2">{ t('Estimated GFR less than 30 mL/min (includes patients on dialysis)') }</option>
                </Field>
              </div>

              <div className="field -full">
                <label htmlFor="Cancer">{ t('Cancer (other than Leukaemia, Lymphoma and Myeloma)') }</label>
                <Field name="cancer1" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="1">{ t('Diagnosed less than 1 year ago') }</option>
                  <option value="2">{ t('Diagnosed between 1 and 5 years ago') }</option>
                  <option value="3">{ t('Diagnosed 5 years ago or more') }</option>
                </Field>
              </div>

              <div className="field -full">
                <label htmlFor="Leukaemia">{ t('Leukaemia, Lymphoma, Myeloma') }</label>
                <Field name="cancer2" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="1">{ t('Diagnosed less than 1 year ago') }</option>
                  <option value="2">{ t('Diagnosed between 1 and 5 years ago') }</option>
                  <option value="3">{ t('Diagnosed 5 years ago or more') }</option>
                </Field>
              </div>

              <div className="field -full">
                <label htmlFor="Heart">{ t('Heart condition') }</label>
                <Field name="heart" as="select" className="select">
                  <option value="None">{ t('None') }</option>
                  <option value="1">{ t('Heart failure') }</option>
                  <option value="2">{ t('Other chronic heart disease') }</option>
                </Field>
              </div>
              <div className="field">
                <div role="group" aria-labelledby="checkbox-group">
                  <label>
                    <Field type="checkbox" name="comorbid" value="Other chronic respiratory disease" />
                    { t('Other chronic respiratory disease') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Hypertension" />
                    { t('Hypertension') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Cerebrovascular disease" />
                    { t('Cerebrovascular disease (including Stroke)') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Liver disease" />
                    { t('Liver disease') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Chronic neurological disease other than stroke or dementia" />
                    { t('Chronic neurological disease other than stroke or dementia') }
                    <p className="annot">{ t('(e.g. Motor Neurone Disease, Myasthenia Gravis, Multiple Sclerosis, Parkinson’s Disease, Cerebral Palsy, Quadriplegia, Hemiplegia, Malignant Primary Brain Tumour and Progressive Cerebellar Disease.)') }</p>
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Organ transplant" />
                    { t('Organ transplant') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Spleen diseases" />
                    { t('Spleen diseases') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Rheumatoid/lupus/psoriasis" />
                    { t('Rheumatoid / Lupus / Psoriasis') }
                  </label>
                  <label>
                    <Field type="checkbox" name="comorbid" value="Other immunosuppressive condition" />
                    { t('Other immunosuppressive condition ') }
                    <p className="annot">{ t('(e.g. HIV, previous/current conditions inducing Permanent Immunodeficiency, Aplastic Anaemia, and Temporary Immunodeficiency recorded within the past year)') }</p>
                  </label>
                </div>
              </div>
              <p className="formsubtext">{ t('The COVID-19 Health Hazard Score is based on multiple factors\' hazard ratio.') }<br />
              { t('To learn more about the science behind the COVID-19 Health Hazard Assessment, ') }<a href="/whitepaper.pdf" target="_blank">{ t('click here.') }</a></p>
              { errors.age && touched.age && <div className="error">{validation_error_msg}</div> }
              <input type="submit" onClick={() => {toResult(errors)}} value={ t('Calculate') }/>
            </Form>
          </div>
        </div>
        <div className="form_foot">
          <h2>{ t('Disclaimer') }</h2>
          <p>{ t('Note the COVID-19 Health Hazard Score is designed for the Japan’s population including other ethnicities living in Japan and is not applicable to other countries.') }</p>
          <p>{ t('The risk assessment and score are only meant to be used as a general reference based on demographics and comorbidities and should not be taken as the absolute result or diagnosis for an individual. ') }</p>
          <p>{ t('Please consult a qualified health professional for clinical judgement of an individual level recommendation.') }</p>
          <p>{ t('Personal and health information entered in calculating the COVID-19 Health Hazard Score is not saved or recorded by GeneLife or Genesis Healthcare and is not shared with any third party.') }</p>
          <p>{ t('Refer to our privacy policy for general terms and conditions.') }</p>
        </div>
      </div>
    </div>
  )
};

const CvForm = withFormik({
  validateOnMount: true,
  mapPropsToValues({age, gender}) {
    return {
      age: age || '',
      gender: gender || ''
    }
  },
  validate: values => {
    const errors = {};
    if (values.age < 20 || values.age > 75 || values.age.match(/[^0-9]+/)) {
      errors.age = 'Invalid age.';
    }
    return errors;
  },
  handleSubmit(values, { resetForm, setErrors, setStatus, setSubmitting, history }){
    resetForm();
  }
})(PostForm)

export default CvForm;
