import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "COVID-19 Health Hazard Score": "COVID-19 Health Hazard Score",
      "WORKPLACE CONSIDERATIONS": "WORKPLACE CONSIDERATIONS",
      "PRECAUTIONS": "PRECAUTIONS",
      "PREGNANCY": "PREGNANCY",
      "pregnancy_info": "Based on current research, there is no significant evidence of increased risk or increased fatality rates in mother or baby in most cases unless mother has significant medical problems. \n\nThere is emerging evidence of increased hospitalisation with severe symptoms in older mothers and in some ethnic minority mothers.\n\nConsulting a medical practitioner on a regular basis is suggested to assess best approach based on the on-going evolution of the pandemic.\n\nCurrent advice is to minimise the risk to pregnant women, while allowing them to choose whether to attend work and what role to undertake at work. Risk should be reduced as far as reasonably practicable.\n\nAdvised to avoid roles where a degree of risk cannot be avoided, such as clinical work, care work and working closely with others.\n\nParticular care should be taken in the third trimester, especially for mothers from the at-risk groups including older mothers and ethnic minorities.\n\nMothers with known additional risk factors such as congenital heart disease will be given specific advice by their antenatal team.",
      //SnsShare
      "twitter": "http://twitter.com/share?url=https://genelife.jp/covid19-score/&text=Assess+your+vulnerability+for+developing+severe+COVID-19+%26+related+health+hazard+risks.+Check+it+out+at",
      "facebook": "http://www.facebook.com/share.php?quote=Assess+your+vulnerability+for+developing+severe+COVID-19+%26+related+health+hazard+risks.+Check+it+out+at&u=https://genelife.jp/covid19-score/",
    }
  },
  ja: {
    translation: {
      // CVForm
      "COVID-19 Health Hazard Score": "COVID-19 Health Hazard Score",
      "Based on Health Conditions": "リスク評価",
      "Assess your vulnerability for developing severe COVID-19 and related health hazard risks.": "COVID-19重症化やその他健康上のリスクについて評価します。",
      "Health risks": "",
      "Fill the medical conditions questionnaire below to assess your COVID-19 Health Hazard Score.": "以下の質問票に回答してください。",
      "Age (minimum 20-years old, maximum 75 years old)": "年齢（20歳から75歳まで）",
      "Research has focused on patients from 20 to 75 years old and as a result the COVID-10 Health Hazard Score will only be applied to this age group.": "判定に用いた文献が20歳から75歳を対象としているため、この年齢範囲内についてのみ対象となります。",
      "Gender": "性別",
      "Male": "男性",
      "Female": "女性",
      "Height (cm)": "身長 (cm)",
      "Weight (kg)": "体重 (kg)",
      "Ethnicity": "民族性",
      "None": "なし",
      "Japanese": "日本",
      "Asian (South or East Asian)": "アジア（南もしくは東アジア）",
      "Caucasian or East European": "欧州（東欧を含む）",
      "African or African American": "アフリカもしくはアフリカ系",
      "Hispanic or Latino": "ヒスバニックもしくはラテン系",
      "American Indian or Alaska Native": "米大陸原住民",
      "Native Hawaiian or other Pacific Islanders": "ハワイその他太平洋諸島",
      "Mixed ethnicities": "複数",
      "Another ethnicity": "その他",
      "None of the above": "いずれでもない",
      "Asthma": "喘息",
      "Mild": "軽度",
      "Severe": "重度",
      "Diabetes": "糖尿病",
      "Type 1 HbA1c less than or equal to 7.5% (58 mmol/mol) in past year": "過去1年間のHbA1c値が7.5%（58mmol/mol）以下の1型糖尿病　",
      "Type 1 HbA1c greater than 7.5% (58 mmol/mol) in past year": "過去1年間のHbA1c値が7.5%（58mmol/mol）以上の1型糖尿病　",
      "Type 1 HbA1c unknown": "1型糖尿病　（HbA1c値不明）",
      "Type 2 and other HbA1c less than or equal to 7.5% (58 mmol/mol) in past year": "過去1年間のHbA1c値が7.5%（58mmol/mol）以下の2型糖尿病　",
      "Type 2 and other HbA1c greater than 7.5% (58 mmol/mol) in past year": "過去1年間のHbA1c値が7.5%（58mmol/mol）以上の2型糖尿病　",
      "Type 2 and other HbA1c unknown": "2型糖尿病　（HbA1c値不明）",
      "Chronic kidney disease": "慢性腎臓病",
      "(Glomerular filtration rate <60mL/min/1.73m2, as estimated from the most recent serum creatinine measurement)": "（eGFR(推算糸球体濾過量)が60mL/min/1.73m2　未満）",
      "Estimated GFR 30-60 mL/min": "eGFR(推算糸球体濾過量)が30-60 mL / min/1.73m2",
      "Estimated GFR less than 30 mL/min (includes patients on dialysis)": "eGFR(推算糸球体濾過量)が30mL / min/1.73m2　未満　（透析中の患者を含む）",
      "Cancer (other than Leukaemia, Lymphoma and Myeloma)": "がん（白血病、リンパ腫、骨髄腫を除く） ",
      "Diagnosed less than 1 year ago": "過去1年以内に診断された",
      "Diagnosed between 1 and 5 years ago": "過去5年以内に診断された",
      "Diagnosed 5 years ago or more": "5年以上前に診断された",
      "Leukaemia, Lymphoma, Myeloma": "白血病、リンパ腫、骨髄腫 ",
      "Heart condition": "心臓の異常",
      "Heart failure": "心不全",
      "Other chronic heart disease": "その他の慢性心疾患",
      "Other chronic respiratory disease": "その他の慢性呼吸器疾患",
      "Hypertension": "高血圧",
      "Cerebrovascular disease (including Stroke)": "脳血管疾患（脳卒中を含む）",
      "Liver disease": "肝疾患",
      "Chronic neurological disease other than stroke or dementia": "脳卒中または認知症以外の慢性神経疾患",
      "(e.g. Motor Neurone Disease, Myasthenia Gravis, Multiple Sclerosis, Parkinson’s Disease, Cerebral Palsy, Quadriplegia, Hemiplegia, Malignant Primary Brain Tumour and Progressive Cerebellar Disease.)": "（例：運動ニューロン疾患、重力筋無力症、多発性硬化症、パーキンソン病、脳性麻痺、四肢麻痺、片麻痺、悪性原発性脳腫瘍、進行性小脳疾患）",
      "Organ transplant": "臓器移植",
      "Spleen diseases": "脾臓の病気 ",
      "Rheumatoid / Lupus / Psoriasis": "リウマチ/全身性エリテマトーデス/乾癬 ",
      "Other immunosuppressive condition ": "その他の免疫の異常",
      "(e.g. HIV, previous/current conditions inducing Permanent Immunodeficiency, Aplastic Anaemia, and Temporary Immunodeficiency recorded within the past year)": "（例：HIV、過去1年以内（現在継続していない場合を含む）の、永続的な免疫不全、再生不良性貧血、および一時的な免疫不全）",
      "The COVID-19 Health Hazard Score is based on multiple factors' hazard ratio.": "COVID-19ハザードスコアは、複数の要素から判定されます。",
      "To learn more about the science behind the COVID-19 Health Hazard Assessment, ": "このスコアの科学的根拠についてさらに知りたい方は、",
      "click here.": "こちらをご参照ください。",
      "Calculate": "Check",
      "Disclaimer": "免責事項",
      "Note the COVID-19 Health Hazard Score is designed for the Japan’s population including other ethnicities living in Japan and is not applicable to other countries.": "新型コロナのハザードスコアは、日本に住む方（日本人以外を含む）を対象として設計されており、他国には適用されないことに注意してください。",
      "The risk assessment and score are only meant to be used as a general reference based on demographics and comorbidities and should not be taken as the absolute result or diagnosis for an individual. ": "このリスク評価とハザードスコアは、人口的な統計と併発している疾患に基づく一般的な参照情報としてのみ使用されることを意図しており、個々人の確定的な結果や診断として解釈されるべきではありません。",
      "Please consult a qualified health professional for clinical judgement of an individual level recommendation.": "個人としての推奨事項は、必ず医療従事者にご相談ください。",
      "Personal and health information entered in calculating the COVID-19 Health Hazard Score is not saved or recorded by GeneLife or Genesis Healthcare and is not shared with any third party.": "COVID-19ハザードスコアの計算のために入力された個人情報（健康に関するものを含む）は、GeneLifeまたはGenesis Healthcareが保存・記録したり、第三者と共有したりすることはありません。",
      "Refer to our privacy policy for general terms and conditions.": "利用規約および個人情報の取扱について",
      "Invalid age.": "年齢が不正です",
      // CvResult
      "Reference critical hazard": "参照されたハザード要素",
      "Your COVID-19 Health Hazard Score": "あなたのCOVID-19ハザードスコア",
      "hazard risk": "ハザードリスク",
      "WORKPLACE CONSIDERATIONS": "職場における注意事項",
      "PRECAUTIONS": "予防",
      "PREGNANCY": "妊婦",
      "Show more": "結果を見る",
      "pregnancy_info": "現在までの研究に基づけば、母親に重大な健康上の問題がなければ、母親または赤ちゃんの重症化リスクが増加する、あるいは死亡率が増加するという有意な科学的根拠はありません。\n\n高年齢の妊婦や一部の少数民族の妊婦では、重症化による入院の増加に関する新たな科学的根拠がでてきています。\n\nパンデミックが起こっているときなど対策は妊婦健診で医師に相談しましょう。\n\n標準的な予防対策を徹底し、ご自身の体調なども踏まえ、リモートワーク、休暇の取得などについて、勤務先とご相談ください。\n\n妊娠後期の妊婦の方は重症化しやすく、感染予防に特に気を配る必要があります。\nまた、高年齢の妊婦の方や生活習慣病、、先天性心疾患などの疾患をお持ちの方も、特に注意を払う必要があります。",
      "About the COVID-19 Health Hazard Score": "COVID-19ハザードスコアについて",
      "Regardless of your COVID-19 Health Hazard Score, it is important to take and maintain all precautionary actions to prevent exposure and spread of the virus.": "COVID-19ハザードスコアとは関係なく、ウイルス感染と拡散を防ぐためには、あらゆる予防措置を講じることが重要です。",
      "Remain informed about the evolution of the virus by consulting regularly the guidelines issued by health authorities and consult a medical practitioner whenever you might have some questions.": "厚生労働省のガイドラインを定期的に参照してウイルスに関する新しい情報を把握し、質問がある場合は医師に相談してください。",
      "To learn more about the science behind the COVID-19 Health Hazard Score, ": "COVID-19ハザードスコアアの科学的根拠についてさらにに知りたい方は、",
      "Share with friends and family": "友人、家族と共有する。",
      "NEED A COVID-19 PCR TEST?": "COVID-19 PCR検査キットが必要ですか？",
      "Order now": "購入",
      "Order a GeneLife COVID-19 PCR kit with global testing protocols! ": "COVID-19 PCR(日本基準＋国際的水準)検査キットをご購入いただけます。",
      "Order a GeneLife COVID-19 PCR kit according to Japanese testing protocols, even if asymptomatic.": "無症状でもCOVID-19 PCR(日本基準)検査キットをご購入いただけます。",
      "Learn from your genetics about COVID-19 and how to maximize your health and wellness during pandemic times.": "COVID-19についての遺伝的素因を知り、コロナ禍でもより健康的になる方法を学びましょう。",
      "Order Genesis2.0 Plus with special COVID-19 report.": "COVID-19重症化リスクの検査項目が追加されたGenesis2.0プラスを注文する",
      "For existing Genesis2.0 customers, purchase the additional COVID-19 report via our mobile app.": "既にGenesis2.0をご利用いただいているお客様は、モバイルアプリ経由で、追加のCOVID-19のレポートをご購入ください。",
      "For existing Myself2.0 customers, purchase Genesis2.0 with special COVID-19 report at a discounted price via our mobile app.": "既にMyself2.0をご利用いただいているお客様は、モバイルアプリ経由で、COVID-19のレポートを含むGenesis2.0を割引価格にてご購入ください。",
      // CvRiskChart
      "Age": "年齢",
      "Comorbidities": "併存症",
      //SnsShare
      "twitter": "http://twitter.com/share?url=https://hazardscore.genelife.jp/covid19&text=%E3%81%82%E3%81%AA%E3%81%9F%E3%81%AE%E5%81%A5%E5%BA%B7%E7%8A%B6%E6%85%8B%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%84%E3%81%A6COVID-19%E9%87%8D%E7%97%87%E5%8C%96%E3%82%84%E9%96%A2%E9%80%A3%E3%81%99%E3%82%8B%E5%81%A5%E5%BA%B7%E4%B8%8A%E3%81%AE%E3%83%AA%E3%82%B9%E3%82%AF%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E8%A9%95%E4%BE%A1%E3%81%97%E3%81%BE%E3%81%99.+%E4%BB%A5%E4%B8%8B%E3%81%AE%E3%83%AA%E3%83%B3%E3%82%AF%E3%81%8B%E3%82%89%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82",
      "facebook": "http://www.facebook.com/share.php?quote=%E3%81%82%E3%81%AA%E3%81%9F%E3%81%AE%E5%81%A5%E5%BA%B7%E7%8A%B6%E6%85%8B%E3%81%AB%E5%9F%BA%E3%81%A5%E3%81%84%E3%81%A6COVID-19%E9%87%8D%E7%97%87%E5%8C%96%E3%82%84%E9%96%A2%E9%80%A3%E3%81%99%E3%82%8B%E5%81%A5%E5%BA%B7%E4%B8%8A%E3%81%AE%E3%83%AA%E3%82%B9%E3%82%AF%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6%E8%A9%95%E4%BE%A1%E3%81%97%E3%81%BE%E3%81%99.+%E4%BB%A5%E4%B8%8B%E3%81%AE%E3%83%AA%E3%83%B3%E3%82%AF%E3%81%8B%E3%82%89%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%81%97%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84%E3%80%82&u=https://hazardscore.genelife.jp/covid19",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
