import './i18n'

function ChangeLng(props) {
  return(
    <div>
      {/* <button onClick={() => props.setLang(props.lang === 'en' ? 'ja' : 'en')}>LNG</button> */}
      <div className="lang">
      <div className={props.lang}>
        <span className="en" onClick={() => props.setLang('en')} >English</span> | <span className="ja" onClick={() => props.setLang('ja')} >Japanese</span>
      </div>
      </div>
    </div>
  );
}

export default ChangeLng;
