import React from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import './i18n'

const CvRiskChart = props => {
  const { values } = props;

  const { t } = useTranslation();

  const categories = [t('Age'), t('Gender'), t('BMI'), t('Ethnicity'), t('Comorbidities')];

  const chartval = {
    series: [{
      name: t('Reference critical hazard'),
      data: values[0],
    }, {
      name: t('Your COVID-19 Health Hazard Score'),
      data: values[1],
    }],
    options: {
      colors:['#BD666A', '#072F8C'],
      chart: {
        width: "100%",
        height: 400,

        type: 'radar',
        toolbar: {
        show: false
                },
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1
        }
      },
      title: {
        text: ''
      },
      stroke: {
        width: 2
      },
      fill: {
        opacity: 0.1
      },
      markers: {
        size: 2
      },
      yaxis: {
        tickAmount: 6,
        max: 6,
        labels: {
          show: false,
          style: {
            fontSize: '0px'
          }
        }
      },
      xaxis: {
        categories: categories
      }
    },
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
          options={chartval.options}
          series={chartval.series}
          type="radar"

          />
        </div>
      </div>
    </div>
  );
};

export default CvRiskChart
