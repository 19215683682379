import { createContext, useState, useEffect } from 'react';
import { Route, Redirect, useLocation, useParams } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import ScrollToTop from './ScrollToTop';
import './i18n'
import { useTranslation } from 'react-i18next';
import CvHome from './CvHome';
import CvForm from './CvForm';
import CvResult from './CvResult';
import WhitePaper from './WhitePaper';

export const CvriskContext = createContext([0, () => {}])

function Covid19() {
  const location = useLocation();
  const [cvrisk, setCvrisk] = useState('');
  const params = new URLSearchParams(useLocation().search);
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(params.get('lng') ?? 'ja');

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return(
    <div>
      <CvriskContext.Provider value={[cvrisk, setCvrisk]}>
        {/* Covid19.js: {cvrisk} */}
        <ScrollToTop />
        <SlideRoutes location={location}>
          {/* <Route path="/" component={CvHome} exact /> */}
          <Route path="/" exact><Redirect to="/covid19" /></Route>
          <Route path="/covid19" render={ () => <CvForm setLang={setLang} lang={lang} /> } exact />
          <Route path="/covid19/result" render={ () => <CvResult setLang={setLang} lang={lang} /> } />
          <Route path="/covid19/wp" component={WhitePaper} />
        </SlideRoutes>
      </CvriskContext.Provider>
    </div>
  );
}

export default Covid19;
